.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.card {
  border: solid 1px #dfdfdf;
  border-radius: 5px;
}
.symbol-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.symbol-group.symbol-hover .symbol {
  cursor: pointer;
}
.symbol-group .symbol {
  position: relative;
  z-index: 0;
  margin-left: -10px;
  transition: all 0.3s ease;
}
.symbol.symbol-circle, .symbol.symbol-circle > img, .symbol.symbol-circle .symbol-label {
  border-radius: 50%;
}
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.475rem;
}
.symbol.symbol-35px > img {
  width: 35px;
  height: 35px;
}

.tooltip-inner {
  background-color: #2f4fff;
  box-shadow: 0px 0px 4px black;
  opacity: 1 !important;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
  border-right-color: #2f4fff !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: #2f4fff !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #2f4fff !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #2f4fff !important;
}  
.app-button:hover {
  color: white;
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #736ced;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}    
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #ffffff !important;
  background-color: #736ced !important;
}
/*.nav-tabs {
  --bs-nav-tabs-border-width: 0px; 
  --bs-nav-tabs-border-color: #736ced;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #736ced;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}*/
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #736ced;
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.bgApp{
background: "#736CED";
}
.accordion-item{
  border: 0px;
}
.accordion-button{
  border: 0px  transparent;
}  
.accordion-button:not(.collapsed){

  background-color: #fff;
} 
.accordion-button:focus{
  border-color:#fff;
  box-shadow: none;
}
.react-calendar__tile--active{
  background: #736ced;
  color: white;
  border-radius: 8px;
}           
abbr[title]{
  text-decoration: none;
}
.form-select:disabled {
  background-color: #f3f3f3;
  border: 0px;
}
.form-select {
  background-color: #f3f3f3;
  border: 0px;
}

.activo:hover{
  background-color: #dcdefc;
  color:#736ced;
  cursor: pointer;
}
.circuloFecha{
  border-radius: 50% ;
  background: #727cf5 ;
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fixed-column {
  position: sticky;
  top: 0;
  height: 100%; /* Establece la altura total de la columna */
   /* Permite desplazamiento vertical si el contenido excede la altura */
}
.aling-fecha{
  display: flex;
  justify-content: space-between;
}
.agendaCard{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.draggable-element {
  cursor: move;
  user-select: none;
}
.dragging {
  cursor: move;
}
ul {
  list-style-type: none;
}

select option:checked {
  background-color: #727cf5;
  color: #ffffff;
}
select option:hover {
  background-color: #727cf5;
  color: #ffffff;
}
select option {
  font-size: 1rem;
  border: none !important;
}
.calendarioVertica{
  border-radius: 10px;
  padding: 20px;
}
.circuloCero{
  width: 35px;
    height: 35px;
    background: rgb(115 108 237);
    color: rgb(255, 255, 255);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circuloCeroItems{
  width: 35px;
    height: 35px;
    background: rgb(197, 197, 198);
    color: rgb(255, 255, 255);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cl{
  width: 3rem;
  height: 3rem;
}
.td{
  height: 3rem; 
}
.circuloCeroSm{
  width: 20px;
    height: 20px;
    background: #3b82f6;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circuloCeroItemsSm{
  width: 20px;
  height: 20px;
  background: #ffba57;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circuloCeroFn{
  width: 20px;
  height: 20px;
  background: #0acf97;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-pic {
  width: 53px; /* 35px de imagen + 2px de borde a cada lado */
  height: 53px; /* 35px de imagen + 2px de borde a cada lado */ 
  border: 2px solid transparent;
  border-radius: 50%;
  overflow: hidden; /* Para ocultar cualquier parte de la imagen fuera del círculo */
  display: inline-block;
  margin-right: 3px; /* Espacio entre el contenedor y otros elementos */
}

.profile-pic img {
  width: 49px;
  height: 49px;
  object-fit: cover; /* Para ajustar la imagen dentro del contenedor */
}
.profile-pic:active {
  width: 53px; /* 35px de imagen + 2px de borde a cada lado */
  height: 53px; /* 35px de imagen + 2px de borde a cada lado */
  border: 2px solid  #736ced;
  border-radius: 50%;
  overflow: hidden; /* Para ocultar cualquier parte de la imagen fuera del círculo */
  display: inline-block;
  margin-right: 3px; /* Espacio entre el contenedor y otros elementos */
}
.profile-pic:hover {
  width: 53px; /* 35px de imagen + 2px de borde a cada lado */
  height: 53px; /* 35px de imagen + 2px de borde a cada lado */
  border: 2px solid  rgb(115 108 237);
  border-radius: 50%;
  overflow: hidden; /* Para ocultar cualquier parte de la imagen fuera del círculo */
  display: inline-block;
  margin-right: 3px; /* Espacio entre el contenedor y otros elementos */
}
.profile-customer img {
  width: 70px;
  height: 70px;
  object-fit: cover; /* Para ajustar la imagen dentro del contenedor */
  border-radius: 50%;
}

.profile_data {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  
}
.profile-active:hover{
 background: #6c757d0f;
 cursor: pointer;
}
.active-profile{
  background: #6c757d0f;
 }
 .profile-scrollable {
  height: 35vh;
  overflow-y: scroll; /* Añade scroll vertical */
}
.profile-scrollable::-webkit-scrollbar {
  width: 4px; /* Ancho de la barra de desplazamiento */
}

.profile-scrollable::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color del fondo de la pista */
}

.profile-scrollable::-webkit-scrollbar-thumb {
  background: #888; /* Color del thumb (manija) */
  border-radius:2px; /* Borde redondeado del thumb */
}

.profile-scrollable::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color del thumb en hover */
}
.agenda-scrollable {
  height: 90vh;
  overflow-y: scroll; /* Añade scroll vertical */
}
.agenda-scrollable::-webkit-scrollbar {
  width: 4px; /* Ancho de la barra de desplazamiento */
}

.agenda-scrollable::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color del fondo de la pista */
}

.agenda-scrollable::-webkit-scrollbar-thumb {
  background: #888; /* Color del thumb (manija) */
  border-radius:2px; /* Borde redondeado del thumb */
}

.agenda-scrollable::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color del thumb en hover */
}
.btn-close{
  font-size: 2rem;
}
.slider{
 /* aspect-ratio: 10/ 16;*/
  width: 100%;
  position: relative;
  display: flex;
  overflow: scroll;
  scroll-snap-type: x mandatory;
}
.slider .img{
  width: 100%;
  left: 0;
  position: sticky;
  object-fit: cover;
  border-radius: 5px;
  scroll-snap-align: center;
}
.estilo-x {
  font-size: 0.8rem;
}
/*.row>* {
  padding-left: 0px;
  padding-right: 0px;
}*/
.shadow{
  box-shadow: none !important;
}

/* pulse in SVG */
svg.pulse-svg {
  overflow: visible; }
  svg.pulse-svg .first-circle, svg.pulse-svg .second-circle, svg.pulse-svg .third-circle {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite;
    fill: #3a7afe; }
  svg.pulse-svg .second-circle {
    -webkit-animation-delay: 1s;
    animation-delay: 1s; }
  svg.pulse-svg .third-circle {
    -webkit-animation-delay: 2s;
    animation-delay: 2s; }

/* pulse in CSS */
.pulse-css,
.pluse-css-1 {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border-radius: 3.5rem;
  height: .4rem;
  position: absolute;
  background: #3a7afe;
  right: 5px;
  top: .6rem;
  width: .4rem; 
}
  .pulse-css:after, .pulse-css:before,
  .pluse-css-1:after,
  .pluse-css-1:before {
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -.2rem;
    background-color: #3a7afe;
    margin: auto;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite; 
  }

    [direction="rtl"] .pulse-css:after, [direction="rtl"] .pulse-css:before, [direction="rtl"]
    .pluse-css-1:after, [direction="rtl"]
    .pluse-css-1:before {
      left: auto;
      right: -.2rem; }

.pluse-css-1 {
  background-color: #f25767; }
  .pluse-css-1:after, .pluse-css-1:before {
    background-color: #f25767; }

.new {
  position: relative; 
}
  .new:after {
    content: "";
    position: absolute;
    background-color: #f25767;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 30px;
    right: 27px;
    border: 2px solid #fff; 
  }
    @media only screen and (max-width: 767px) {
      .new:after {
        top: 26px;
        right: 9px; } 
  }

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0; }
  50% {
    opacity: 0.1; }
  70% {
    opacity: 0.09; }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0; }
  }

@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0; }
  50% {
    opacity: 0.1; }
  70% {
    opacity: 0.09; }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0; }
  }
.social {
    display: flex;
    align-items: center;
    gap: 10px;
}
.perfil{
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
}
.active:hover{
 cursor: pointer;
}
.row-cro{
  display: flex;
  align-content: space-between;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
#color-button-exito {
  background-color: #0acd6e;
  border-radius: 5px;
  width: 200px;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px;
  cursor: pointer;
  position: relative;
  height:45px
}

#color-button {
  background-color: #0d6efd;
  border-radius: 5px;
  width: 200px;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px;
  cursor: pointer;
  position: relative;
  height:45px
}

#color-button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: #579bff;
  transition: width 8s linear;
}

#color-button.active::before {
  width: 100%;
}

#color-button.hide {
  display: none;
}