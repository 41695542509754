.navbar {
  
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 100;
  transition: height 0.3s ease;
}

.navbar-logo {
  display: flex;
  align-items: center;
  height: 60px;
  transition: transform 0.3s ease;
}

.navbar-logo-img {
  height: 40px;
  transition: transform 0.3s ease;
}

.content {
  height: 2000px; /* Altura de la página para permitir desplazamiento */
}

.navbar.scrolled {
  height: 80px; /* Altura de la barra de navegación al hacer scroll */
}

.navbar-logo.scrolled {
  transform: translateY(-50%);
}

.navbar-logo-img.scrolled {
  height: 60px;
}

.header-web {
  height: 18rem;
  background: transparent;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1rem;
  color: white;
}
.header-contact {
  display: flex;
  gap: 1rem;
}
.header-ubicacion {
  position: absolute;
  width: 100%;
  top: 80%;
  left: 1px;
}

.header-icon {
  background: grey;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-content-icon {
  background: #fff;
  color: black;
  height: 2.4rem;
  border-radius: 1.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  padding: 0.2rem;
}
.header-text {
  padding: 5px;
}
.social-container{
  display: flex;
  justify-content: space-around;

}
.card-web {
  border: solid 1px transparent;
  border-radius: 30px;
  background: white;
  color: black;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  top: 75%;
}
.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #fff ;
  border: solid 1px transparent;
}
.accordion-button:active{
  color: #000000;
  background-color: #fff ;
  border: solid 1px transparent;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #fff !important;
  background-color: #fff;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Cambiar el fondo de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background-color: transparent !important;
}

/* Cambiar el color del "pulgar" de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  background-color: #cccccc;
}

/* Estilos para navegadores basados en Firefox */
/* Cambiar el ancho y el color de la barra de desplazamiento */
/* Nota: en Firefox, el selector es ::scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: #cccccc transparent;
}
/* Ocultar la barra de desplazamiento nativa */




.carousel-item{
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 0;
  gap: 1em;
  height: 200px;
}
.item-ca{
  object-fit: cover;
  flex: 0 0 100%;
  scroll-snap-align: start;
}
.btnCenter {
  padding: 0px; 
  position: fixed; 
  z-index: 2147483646; 
  border-radius: 16px; 
  top: auto; 
  background: none; 
  width: 399px; 
  bottom: 100px; 
  max-height: 2px; 
  right: 2px; 
  height: 33px; 
  visibility: visible; 
  min-height: 0px;
}

@media only screen and (max-width: 1400px) {
 .btnCenter {
      padding: 0px;
      position: fixed;
      z-index: 2147483646;
      border-radius: 16px;
      top: auto;
      background: none;
      width: 100px;
      bottom: 100px;
      max-height: 0px;
      right: 2px;
      height: 33px;
      visibility: visible;
      min-height: 0px;
}
}
.lista-horizontal {
  display: flex; /* O utiliza display: inline-block; */

  gap: 1em;
  border: solid 1px transparent;
  background: #f5f5f5;
  color: rgb(171, 171, 171);
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.elemento-lista {
  border: solid 1px transparent;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.dias{
  display: flex;
}