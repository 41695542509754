 .account-user-avatar img {
    width:2rem !important;
    height:2rem !important;
}
.imgf {
    width:2rem !important;
    height:2rem !important;  
}
.cronogram-bg {
   background: #dcdefc;
   color: #736ced;
}
.cronogram-text {
  color: #736ced;
}
.App-logo-sm {
    height: 3vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo-sm {
      animation: App-logo-spin infinite 15.5s ;
    }
  }


