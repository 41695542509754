.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #8391a2 !important;
    background-color: #313a46 !important;
}
.account-user {
    width: 100px;
    height: 100px;
}
.rounded-circle {
    width: 100px;
    height: 100px;
}
.pt-8{
 padding-top: 3rem;
}

.badgesA {
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    cursor: pointer;
}