.containers {
  height: 50vh;
  width: auto;
}

.container-cropper {
  height: 90%;
  padding: 10px;
}

.cropper {
  height: 90%;
  position: relative;
}

.slider {
  height: 10%;
  display: flex;
  align-items: center;
  margin: auto;
  width: 60%;
}

.container-buttons {
  border: none;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}