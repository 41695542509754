.bg-calendar{
    background: #fefefe;
    color: rgb(142, 142, 142);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
}
.linea-derecha{
    border-right: #dadce0 1px solid;
    position: relative;
    padding-right: 12px;
    box-sizing: border-box;
    flex: 1 1 auto;
}
.linea-izquierda{
    border-left: #dadce0 1px solid;
    position: relative;
    padding-left: 12px;
    box-sizing: border-box;
    flex: 1 1 auto;
}
.linea-top{
    border-top:  #dadce0 1px solid;
}
.agenda__reservas td {
    background-color: #0077be;
    color: white;
  }
  
  .agenda__horarios td {
    position: relative;
  }
  
  .agenda__reserva {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #0077be;
    color: white;
    z-index: 1;
  }
  .hidden-element {
    display: none;
  }
  
  .hoverable:hover .hidden-element {
    display: block;
  }


  .draggable-item {
    cursor: grab;
  }
  
  .item:hover {
    
    opacity: 0.8 !important;
    background: '';
  }

  .botonturno{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f3f3f3;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    
    margin-right: auto!important;
    margin-left: auto!important;
    cursor: pointer;
    font-size: 1.2rem;
  }
  .cliente-lista{
    border-top: 1px solid rgba(134, 150, 160, 0.15);
    display: flex;
    align-content: space-between;
    padding: 20px;
    cursor: pointer;
  }
  .cliente-lista:hover{
    background: #6c757d0f;
  }
  .box-age{
    box-shadow: rgba(79, 79, 79, 0.1) 0px 4px 15px;
    padding: 15px;
    margin: 5px;
    border-radius: 5px;
  }
  .total{
    background: #f3f3f3;
    display: flex;
    /* justify-content: center; */
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    flex-direction: row;
    align-items: center;
    align-content: center;

  }
.nav-cro{
  display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.cont-cro{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}
.card-cro{
  box-shadow: 0px 4px 15px rgba(79,79,79,0.2);
  padding: 15px;
  margin: 5px;
  border-radius: 5px;
}


