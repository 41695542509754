input,select {
    font-family: Proxima Nova,-apple-system,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif
}

.u-clearfix:after,.u-clearfix:before {
    content: "";
    display: table
}

.u-clearfix:after {
    clear: both
}

.u-fw-300 {
    font-weight: 300
}

.u-fw-400 {
    font-weight: 400
}

.u-fw-600 {
    font-weight: 600
}

.u-fw-800 {
    font-weight: 700
}

.u-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}







fieldset {
    border: 0;
    padding: 0
}

[required] {
    -webkit-box-shadow: none;
    box-shadow: none
}

.m-t-8 {
    margin-top: 8px
}

.m-t-16 {
    margin-top: 16px
}

.m-b-24 {
    margin-bottom: 24px
}

.m-r-8 {
    margin-right: 8px
}

.crono-progress-indicator-circular {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative
}

.crono-progress-indicator-circular--blue {
    stroke: #009ee3
}

.crono-progress-indicator-circular--white {
    stroke: #fff
}

.crono-progress-indicator-circular__content {
    color: rgba(0,0,0,.8);
    font-size: 12px;
    font-weight: 400
}

.crono-progress-indicator-circular--indeterminate .crono-progress-indicator-circular__svg {
    -webkit-animation: crono-pi-circular-rotate 2s linear infinite;
    animation: crono-pi-circular-rotate 2s linear infinite;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1022
}

.crono-progress-indicator-circular--indeterminate .crono-progress-indicator-circular__circle {
    stroke-linecap: round;
    -webkit-animation: crono-pi-circular-dash 1.5s ease-in-out infinite;
    animation: crono-pi-circular-dash 1.5s ease-in-out infinite
}

@-webkit-keyframes crono-pi-circular-rotate {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes crono-pi-circular-rotate {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@-webkit-keyframes crono-pi-circular-dash {
    0% {
        stroke-dasharray: 1,150;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 90,150;
        stroke-dashoffset: -35
    }

    to {
        stroke-dasharray: 90,150;
        stroke-dashoffset: -124
    }
}

@keyframes crono-pi-circular-dash {
    0% {
        stroke-dasharray: 1,150;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 90,150;
        stroke-dashoffset: -35
    }

    to {
        stroke-dasharray: 90,150;
        stroke-dashoffset: -124
    }
}

.crono-progress-indicator-circular--medium {
    stroke-width: 3px;
    height: 32px;
    width: 32px
}

@-webkit-keyframes crono-button-start-width-animation {
    0% {
        width: 0
    }

    to {
        width: 90%
    }
}

@keyframes crono-button-start-width-animation {
    0% {
        width: 0
    }

    to {
        width: 90%
    }
}

.crono-button {
    -webkit-font-smoothing: antialiased;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    display: inline-block;
    font-family: Proxima Nova,-apple-system,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    text-align: center;
    -webkit-transition: .18s ease-out;
    transition: .18s ease-out;
    -webkit-transition-property: background,color;
    transition-property: background,color;
    width: auto
}

.crono-button:focus {
    box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    -moz-box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    -webkit-box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    outline: none
}

.crono-button:focus-visible {
    box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    -moz-box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    -webkit-box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    outline: none
}

.crono-button:focus:not(:focus-visible) {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    outline: none
}

.crono-button:link {
    text-decoration: none
}

.crono-button,.crono-button * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.crono-button svg {
    display: inline-block;
    max-height: 1.125em;
    max-width: 1.125em;
    vertical-align: middle
}

.crono-button--full-width {
    display: block;
    width: 100%
}

.crono-button--disabled {
    pointer-events: none
}

.crono-button--fetching .crono-progress-indicator-circular {
    stroke-width: 6px;
    -webkit-align-items: center;
    align-items: center;
    -webkit-animation: translateInButtonSpinner .2s,fadeIn .2s;
    animation: translateInButtonSpinner .2s,fadeIn .2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    display: inline-block;
    -webkit-justify-content: center;
    justify-content: center
}

.crono-button--fetching .crono-progress-indicator-circular__svg {
    height: 24px;
    left: 25%;
    top: 25%;
    width: 24px
}

.crono-button--fetching .crono-button__content {
    -webkit-animation: translateOutButtonSpinner .2s,fadeOut .2s;
    animation: translateOutButtonSpinner .2s,fadeOut .2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

.crono-button--fetching-complete .crono-button__content {
    -webkit-animation: translateInButtonSpinnerComplete .2s,fadeIn .2s;
    animation: translateInButtonSpinnerComplete .2s,fadeIn .2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

.crono-button__content {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
    justify-content: center
}

.crono-button__content:after {
    clear: both;
    content: "";
    display: table
}

.crono-button__content * {
    display: block;
    float: left
}

.crono-button__text {
    -webkit-flex: 1;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.crono-button__text+svg,svg+.crono-button__text {
    margin-left: 12px
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes translateInButtonSpinner {
    0% {
        -webkit-transform: translate(-webkit-calc(50% - 20px),50%);
        transform: translate(calc(50% - 20px),50%)
    }

    to {
        -webkit-transform: translateY(-webkit-calc(-50% - 22px));
        transform: translateY(calc(-50% - 22px))
    }
}

@keyframes translateInButtonSpinner {
    0% {
        -webkit-transform: translate(-webkit-calc(50% - 20px),50%);
        transform: translate(calc(50% - 20px),50%)
    }

    to {
        -webkit-transform: translateY(-webkit-calc(-50% - 22px));
        transform: translateY(calc(-50% - 22px))
    }
}

@-webkit-keyframes translateOutButtonSpinner {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0)
    }

    to {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }
}

@keyframes translateOutButtonSpinner {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0)
    }

    to {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }
}

@-webkit-keyframes translateOutButtonSpinnerComplete {
    0% {
        -webkit-transform: translateY(-webkit-calc(-50% - 22px));
        transform: translateY(calc(-50% - 22px))
    }

    to {
        -webkit-transform: translateY(-80px);
        transform: translateY(-80px)
    }
}

@keyframes translateOutButtonSpinnerComplete {
    0% {
        -webkit-transform: translateY(-webkit-calc(-50% - 22px));
        transform: translateY(calc(-50% - 22px))
    }

    to {
        -webkit-transform: translateY(-80px);
        transform: translateY(-80px)
    }
}

@-webkit-keyframes translateInButtonSpinnerComplete {
    0% {
        -webkit-transform: translateY(50%);
        transform: translateY(50%)
    }

    to {
        -webkit-transform: translateY(-webkit-calc(-50% + 22px));
        transform: translateY(calc(-50% + 22px))
    }
}

@keyframes translateInButtonSpinnerComplete {
    0% {
        -webkit-transform: translateY(50%);
        transform: translateY(50%)
    }

    to {
        -webkit-transform: translateY(-webkit-calc(-50% + 22px));
        transform: translateY(calc(-50% + 22px))
    }
}

.crono-checkbox {
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    font-family: Proxima Nova,-apple-system,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
    font-size: 16px;
    position: relative
}

.crono-checkbox:not(div) {
    display: -webkit-inline-flex;
    display: inline-flex
}

.crono-checkbox__input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 16px;
    left: 0;
    margin: 10px 0 0;
    outline: none;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 16px
}

.crono-checkbox__input:focus {
    box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    -moz-box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    -webkit-box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    outline: none
}

.crono-checkbox__input:focus-visible {
    box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    -moz-box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    -webkit-box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    outline: none
}

.crono-checkbox__input:focus:not(:focus-visible) {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    outline: none
}

.crono-checkbox__input:before {
    left: 0;
    line-height: 1
}

.crono-checkbox__input:after,.crono-checkbox__input:before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23fff' d='M3 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3z'/%3E%3Cpath fill='%23000' fill-opacity='.25' d='M3 0h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm0 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3z'/%3E%3C/g%3E%3C/svg%3E");
    height: 16px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 16px
}

.crono-checkbox__input:after {
    background: #fff;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    opacity: 0;
    -webkit-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out
}

.crono-checkbox__input:hover:after,.crono-checkbox__input:hover:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23fff' d='M3 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3z'/%3E%3Cpath fill='%23000' fill-opacity='.45' d='M3 0h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm0 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3z'/%3E%3C/g%3E%3C/svg%3E")
}

.crono-checkbox__input[disabled]~.crono-checkbox__label {
    color: rgba(0,0,0,.25)
}

.crono-checkbox__input[disabled] {
    background-color: inherit
}

.crono-checkbox__input[disabled]:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath fill-opacity='.1' fill-rule='evenodd' d='M3 0h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm0 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3z'/%3E%3C/svg%3E")
}

.crono-checkbox__input:checked:after,.crono-checkbox__input:checked:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16'%3E%3Cdefs%3E%3Cpath id='a' d='M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%23009EE3' xlink:href='%23a'/%3E%3Cpath fill='%23fff' fill-rule='nonzero' d='m3.63 7.775-.847.85 3.256 3.245 6.845-6.848-.849-.848-5.998 6z' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E")
}

.crono-checkbox__input:checked:hover:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16'%3E%3Cdefs%3E%3Cpath id='a' d='M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%23007eb5' xlink:href='%23a'/%3E%3Cpath fill='%23fff' fill-rule='nonzero' d='m3.63 7.775-.847.85 3.256 3.245 6.845-6.848-.849-.848-5.998 6z' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E")
}

.crono-checkbox__input:checked[disabled]:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cg fill-rule='evenodd'%3E%3Cpath fill-opacity='.1' d='M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z'/%3E%3Cpath fill-opacity='.25' d='m3.63 7.775-.847.85 3.256 3.245 6.845-6.848-.849-.848-5.998 6z'/%3E%3C/g%3E%3C/svg%3E")
}

.crono-checkbox__input:focus {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    position: absolute
}

.crono-checkbox__input:focus:after {
    opacity: 1
}

.crono-checkbox__label {
    color: rgba(0,0,0,.8);
    display: -webkit-inline-flex;
    display: inline-flex;
    font-family: Proxima Nova,-apple-system,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
    font-size: 16px;
    font-weight: 400
}

.crono-checkbox__label-text {
    padding-left: 1.5em
}

.crono-checkbox--label-before .crono-checkbox__label {
    padding-left: 0;
    padding-right: 1.5em
}

.crono-checkbox--label-before .crono-checkbox__input {
    left: auto;
    right: 0
}

.crono-checkbox--disabled {
    cursor: default
}

.crono-checkbox--without-label {
    height: 16px;
    width: 16px
}

.crono-checkbox--embedded {
    display: inline-block
}

.crono-checkbox--embedded:not(div)>.crono-checkbox__input {
    margin-top: 0;
    top: 3px;
    -webkit-transform: translate(0);
    transform: translate(0)
}

.crono-checkbox--embedded .crono-checkbox__label {
    color: #009ee3;
    font-size: 12px;
    padding-left: 1.1666666667em
}

.crono-checkbox--embedded .crono-checkbox__background {
    background-color: #009ee3;
    content: "";
    height: 32px;
    left: -8px;
    opacity: 0;
    padding: 0 8px;
    position: absolute;
    top: -2px;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    width: 100%
}

.crono-checkbox--embedded .crono-checkbox__input {
    -webkit-border-radius: 0;
    border-radius: 0;
    height: 10px;
    margin-top: 3px;
    top: 50%;
    width: 10px
}

.crono-checkbox--embedded .crono-checkbox__input:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Cpath fill='%23009EE3' d='M2 1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm0-1h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z'/%3E%3C/svg%3E");
    height: 10px;
    top: 38%;
    width: 10px
}

.crono-checkbox--embedded .crono-checkbox__input:checked:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Crect width='10' height='10' fill='%23009EE3' rx='2'/%3E%3Cpath fill='%23fff' d='m7.47 2.47 1.06 1.06L4 8.06 1.47 5.53l1.06-1.06L4 5.94z'/%3E%3C/svg%3E");
    height: 10px;
    width: 10px
}

.crono-checkbox--embedded .crono-checkbox__input:checked+.crono-checkbox__background {
    transition: opacity .1s;
    -webkit-transition: opacity .1s
}

.crono-checkbox--embedded .crono-checkbox__input:checked:hover:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Crect width='10' height='10' fill='%23009EE3' rx='2'/%3E%3Cpath fill='%23fff' d='m7.47 2.47 1.06 1.06L4 8.06 1.47 5.53l1.06-1.06L4 5.94z'/%3E%3C/svg%3E")
}

.crono-checkbox--embedded .crono-checkbox__input:checked[disabled]:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Crect width='10' height='10' fill='%23ccc' rx='2'/%3E%3Cpath fill='%23fff' d='m7.47 2.47 1.06 1.06L4 8.06 1.47 5.53l1.06-1.06L4 5.94z'/%3E%3C/svg%3E");
    height: 10px;
    width: 10px
}

.crono-checkbox--embedded .crono-checkbox__input:hover+.crono-checkbox__background {
    opacity: .06;
    transition: opacity .2s;
    -webkit-transition: opacity .2s
}

.crono-checkbox--embedded .crono-checkbox__input:hover:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Cpath fill='%23009EE3' d='M2 1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm0-1h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z'/%3E%3C/svg%3E")
}

.crono-checkbox--embedded .crono-checkbox__input:focus:after {
    display: none
}

.crono-checkbox--embedded .crono-checkbox__input:focus+.crono-checkbox__background {
    opacity: .12;
    transition: opacity .1s;
    -webkit-transition: opacity .1s
}

.crono-checkbox--embedded .crono-checkbox__input[disabled]:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Cpath fill='%23ccc' d='M2 1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm0-1h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z'/%3E%3C/svg%3E");
    height: 10px;
    width: 10px
}

.crono-checkbox--embedded .crono-checkbox__input[disabled]+.crono-checkbox__background {
    display: none
}

.crono-checkbox--indeterminate .crono-checkbox__input:checked:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16'%3E%3Cdefs%3E%3Cpath id='a' d='M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%23009EE3' xlink:href='%23a'/%3E%3Cpath fill='%23fff' d='M3.478 8.852h9.2v-1.2h-9.2z' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E")
}

.crono-checkbox--indeterminate .crono-checkbox__input:checked:hover:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16'%3E%3Cdefs%3E%3Cpath id='a' d='M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%23007eb5' xlink:href='%23a'/%3E%3Cpath fill='%23fff' d='M3.478 8.852h9.2v-1.2h-9.2z' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E")
}

.crono-checkbox--indeterminate .crono-checkbox__input:checked:after {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16'%3E%3Cdefs%3E%3Cpath id='a' d='M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%23009EE3' xlink:href='%23a'/%3E%3Cpath fill='%23fff' d='M3.478 8.852h9.2v-1.2h-9.2z' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E")
}

.crono-checkbox--indeterminate .crono-checkbox__input:checked[disabled]:before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cg fill-rule='evenodd'%3E%3Cpath fill-opacity='.1' d='M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z'/%3E%3Cpath fill-opacity='.25' d='M3.478 8.852h9.2v-1.2h-9.2z'/%3E%3C/g%3E%3C/svg%3E")
}

.crono-checkbox--error .crono-checkbox__input:not(:checked):before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23fff' d='M3 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3z'/%3E%3Cpath fill='%23F23D4F' d='M3 0h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm0 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3z'/%3E%3C/g%3E%3C/svg%3E")
}

.crono-checkbox--error:hover .crono-checkbox__input:not(:checked):before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23fff' d='M3 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3z'/%3E%3Cpath fill='%23D12440' d='M3 0h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm0 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3z'/%3E%3C/g%3E%3C/svg%3E")
}

.crono-checkbox--error.crono-checkbox--embedded .crono-checkbox__input:not(:checked):before {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Cpath fill='%23F23D4F' d='M2 1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm0-1h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z'/%3E%3C/svg%3E");
    height: 10px;
    width: 10px
}

.crono-checkbox--error.crono-checkbox--embedded .crono-checkbox__input:not(:checked):before:hover {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Cpath fill='%23D12440' d='M2 1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm0-1h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z'/%3E%3C/svg%3E")
}

.crono-button--loud:hover {
    background-color: #007eb5;
    border-color: transparent;
    color: #fff
}

.crono-button--loud:hover path {
    stroke: #fff!important
}

.crono-button--loud {
    -webkit-box-shadow: 0 0 0 0 #fff;
    box-shadow: 0 0 0 0 #fff;
    cursor: pointer;
    -webkit-transition: background-color .2s ease-out,-webkit-box-shadow .25s ease-out;
    transition: background-color .2s ease-out,-webkit-box-shadow .25s ease-out;
    transition: box-shadow .25s ease-out,background-color .2s ease-out;
    transition: box-shadow .25s ease-out,background-color .2s ease-out,-webkit-box-shadow .25s ease-out
}

.crono-button--loud,.crono-button--loud:focus,.crono-button--loud:link,.crono-button--loud:visited {
    background-color: #009ee3;
    border-color: transparent;
    color: #fff
}

.crono-button--loud path,.crono-button--loud:focus path,.crono-button--loud:link path,.crono-button--loud:visited path {
    stroke: #fff!important
}

.crono-button--loud:hover {
    -webkit-transition: background-color .2s ease-in;
    transition: background-color .2s ease-in
}

.crono-button--loud:active {
    background-color: #005e88;
    border-color: transparent;
    color: #fff
}

.crono-button--loud:active path {
    stroke: #fff!important
}

.crono-button--loud:disabled,.crono-button--loud[disabled] {
    background-clip: padding-box;
    background-color: rgba(0,0,0,.1);
    border-color: transparent;
    color: rgba(0,0,0,.25);
    cursor: default
}

.crono-button--loud:disabled path,.crono-button--loud[disabled] path {
    stroke: rgba(0,0,0,.25)!important
}

.amount-page__actions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
    margin-top: 24px
}

.amount__advance,.amount__label-messages {
    text-align: center
}

.amount__advance .crono-checkbox__label-text {
    font-size: 14px
}

.crono-card--padding-default>.crono-card__content,.crono-card--padding-default>.crono-card__footer,.crono-card--padding-default>.crono-card__header {
    padding: 16px
}

.crono-card--padding-0>.crono-card__content,.crono-card--padding-0>.crono-card__footer,.crono-card--padding-0>.crono-card__header {
    padding: 0
}

.crono-card--padding-16>.crono-card__content,.crono-card--padding-16>.crono-card__footer,.crono-card--padding-16>.crono-card__header {
    padding: 16px
}

.crono-card--padding-24>.crono-card__content,.crono-card--padding-24>.crono-card__footer,.crono-card--padding-24>.crono-card__header {
    padding: 24px
}

.crono-card--padding-32>.crono-card__content,.crono-card--padding-32>.crono-card__footer,.crono-card--padding-32>.crono-card__header {
    padding: 32px
}

.crono-card {
    background-color: #fff;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    font-family: Proxima Nova,-apple-system,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif
}

.crono-card--animated {
    -webkit-transition: -webkit-box-shadow .2s ease-in-out;
    transition: -webkit-box-shadow .2s ease-in-out;
    transition: box-shadow .2s ease-in-out;
    transition: box-shadow .2s ease-in-out,-webkit-box-shadow .2s ease-in-out
}

.crono-card--animated:hover,.crono-card--elevated {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0,0,0,.1);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.1)
}

.crono-card--flat {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12)
}

.crono-card--secondary-light {
    background-color: #f5f5f5;
    -webkit-box-shadow: none;
    box-shadow: none
}

.crono-card--secondary-dark {
    background-color: #ededed;
    -webkit-box-shadow: none;
    box-shadow: none
}

.crono-card--outline {
    border: 1px solid rgba(0,0,0,.1)
}

.crono-card>:first-child {
    -webkit-border-top-left-radius: 6px;
    border-top-left-radius: 6px;
    -webkit-border-top-right-radius: 6px;
    border-top-right-radius: 6px
}

.crono-card>:last-child {
    -webkit-border-bottom-left-radius: 6px;
    border-bottom-left-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.crono-card__title {
    color: rgba(0,0,0,.8);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    padding: 0
}

.crono-card__header {
    padding-bottom: 14px
}

.crono-card__header--border {
    border-bottom: 1px solid rgba(0,0,0,.07)
}

.crono-card__footer {
    padding-top: 14px
}

.crono-card__footer--border {
    border-top: 1px solid rgba(0,0,0,.07)
}

.crono-card__footer--link {
    color: #009ee3;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
    -webkit-transition: background-color .15s ease-out;
    transition: background-color .15s ease-out
}

.crono-card__footer--link:focus {
    box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    -moz-box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    -webkit-box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    outline: none
}

.crono-card__footer--link:focus-visible {
    box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    -moz-box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    -webkit-box-shadow: 0 0 0 2px #fff,0 0 0 3px #007eb5,0 0 0 5px rgba(71,154,209,.3);
    outline: none
}

.crono-card__footer--link:focus:not(:focus-visible) {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    outline: none
}

.crono-card__footer--link:hover {
    background-color: #f5f5f5;
    color: #007eb5
}

.crono-card__arrow {
    border-color: #009ee3;
    border-style: solid;
    border-width: 1px 1px 0 0;
    display: block;
    float: right;
    height: 7px;
    margin-top: 5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 7px
}

.crono-card--error .crono-card__content {
    position: relative
}

.crono-card--error .crono-card__content:before {
    background: #f23d4f;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px
}

.crono-card--error .crono-card__content:first-child:before {
    -webkit-border-top-left-radius: 6px;
    border-top-left-radius: 6px
}

.crono-card--error .crono-card__content:last-child:before {
    -webkit-border-bottom-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.crono-card--neutral .crono-card__content {
    position: relative
}

.crono-card--neutral .crono-card__content:before {
    background: #009ee3;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px
}

.crono-card--neutral .crono-card__content:first-child:before {
    -webkit-border-top-left-radius: 6px;
    border-top-left-radius: 6px
}

.crono-card--neutral .crono-card__content:last-child:before {
    -webkit-border-bottom-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.crono-card--success .crono-card__content {
    position: relative
}

.crono-card--success .crono-card__content:before {
    background: #00a650;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px
}

.crono-card--success .crono-card__content:first-child:before {
    -webkit-border-top-left-radius: 6px;
    border-top-left-radius: 6px
}

.crono-card--success .crono-card__content:last-child:before {
    -webkit-border-bottom-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.crono-card--warning .crono-card__content {
    position: relative
}

.crono-card--warning .crono-card__content:before {
    background: #f73;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px
}

.crono-card--warning .crono-card__content:first-child:before {
    -webkit-border-top-left-radius: 6px;
    border-top-left-radius: 6px
}

.crono-card--warning .crono-card__content:last-child:before {
    -webkit-border-bottom-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.hub-base-card .crono-card__header {
    padding: 24px
}

.hub-base-card .crono-card__header h2 {
    font-size: 18px
}

.hub-base-card .crono-card__content {
    padding: 24px
}

.crono-visually-hidden {
    clip: rect(0 0 0 0);
    border: 0;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: 0 -1px -1px 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.crono-amount-field {
    color: rgba(0,0,0,.8);
    -webkit-flex-direction: column;
    flex-direction: column;
    font-weight: 400;
    width: 100%
}

.crono-amount-field,.crono-amount-field__main-container {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex
}

.crono-amount-field__main-container {
    max-width: 100%
}

.crono-amount-field__prefix {
    color: inherit;
    line-height: 1
}

.crono-amount-field__input-container {
    overflow: hidden;
    position: relative
}

.crono-amount-field__input:focus {
    -webkit-animation: bounce .3s;
    animation: bounce .3s;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none
}

.crono-amount-field__input:focus-visible {
    -webkit-box-shadow: none;
    box-shadow: none
}

.crono-amount-field__hidden-content {
    display: block;
    font: inherit;
    opacity: 0;
    pointer-events: none
}

.crono-amount-field__suffix {
    color: rgba(0,0,0,.45);
    line-height: 1
}

.crono-amount-field__helper {
    -webkit-align-items: center;
    align-items: center;
    color: rgba(0,0,0,.45);
    display: -webkit-flex;
    display: flex;
    font-size: 13px;
    line-height: 18px;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap
}

.crono-amount-field .crono-amount-field__error-icon {
    background-color: #f23d4f;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    height: 12px;
    line-height: 1;
    margin-right: 6px;
    width: 12px
}

.crono-amount-field--placeholder {
    color: rgba(0,0,0,.45)
}

.crono-amount-field--type-percentage:not(.crono-amount-field--placeholder) .crono-amount-field__suffix {
    color: rgba(0,0,0,.8)
}

@-webkit-keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    33% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px)
    }

    50% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateY(12px);
        transform: translateY(12px)
    }

    66% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px)
    }

    83% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateY(8px);
        transform: translateY(8px)
    }

    to {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    33% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px)
    }

    50% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateY(12px);
        transform: translateY(12px)
    }

    66% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px)
    }

    83% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateY(8px);
        transform: translateY(8px)
    }

    to {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

.crono-amount-field--size-l .crono-amount-field__input-container {
    font-size: 56px
}

.crono-amount-field--size-l .crono-amount-field__main-container {
    height: 70px
}

.crono-amount-field--size-l .crono-amount-field__suffix {
    font-size: 36px;
    margin-left: 8px;
    margin-top: 12px
}

.crono-amount-field--size-m .crono-amount-field__prefix {
    font-size: 22px;
    margin-right: 6px
}

.crono-amount-field--size-m .crono-amount-field__input-container {
    font-size: 44px
}

.crono-amount-field--size-m .crono-amount-field__main-container {
    height: 55px
}

.crono-amount-field--size-m .crono-amount-field__suffix {
    font-size: 28px;
    margin-left: 8px;
    margin-top: 10px
}

.crono-amount-field--size-s .crono-amount-field__prefix {
    font-size: 18px;
    margin-right: 4px
}

.crono-amount-field--size-s .crono-amount-field__input-container {
    font-size: 36px
}

.crono-amount-field--size-s .crono-amount-field__main-container {
    height: 45px
}

.crono-amount-field--size-s .crono-amount-field__suffix {
    font-size: 24px;
    margin-left: 4px;
    margin-top: 8px
}

.crono-amount-field--size-xs .crono-amount-field__prefix {
    font-size: 18px;
    margin-right: 4px
}

.crono-amount-field--size-xs .crono-amount-field__input-container {
    font-size: 18px
}

.crono-amount-field--size-xs .crono-amount-field__main-container {
    height: 23px
}

.crono-amount-field--size-xs .crono-amount-field__suffix {
    font-size: 18px;
    margin-left: 4px
}

@-webkit-keyframes color-error {
    28% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        color: #f23d4f
    }

    85% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        color: #f23d4f
    }

    to {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        color: rgba(0,0,0,.8)
    }
}

@keyframes color-error {
    28% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        color: #f23d4f
    }

    85% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        color: #f23d4f
    }

    to {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        color: rgba(0,0,0,.8)
    }
}

@-webkit-keyframes bounce-error {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    28% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px)
    }

    43% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateX(16px);
        transform: translateX(16px)
    }

    57% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateX(-16px);
        transform: translateX(-16px)
    }

    71% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateX(16px);
        transform: translateX(16px)
    }

    85% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px)
    }

    to {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes bounce-error {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    28% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px)
    }

    43% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateX(16px);
        transform: translateX(16px)
    }

    57% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateX(-16px);
        transform: translateX(-16px)
    }

    71% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateX(16px);
        transform: translateX(16px)
    }

    85% {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px)
    }

    to {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.crono-amount-field--error .crono-amount-field__main-container {
    -webkit-animation: bounce-error .35s,color-error .35s;
    animation: bounce-error .35s,color-error .35s
}

.crono-amount-field--error .crono-amount-field__suffix {
    color: inherit
}

.crono-amount-field--error .crono-amount-field__helper {
    color: #f23d4f;
    font-weight: 600
}

.crono-amount-field--error.crono-amount-field--type-percentage:not(.crono-amount-field--placeholder) .crono-amount-field__suffix {
    color: inherit
}

.input-amount__field {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    height: 60px;
    -webkit-justify-content: center;
    justify-content: center;
    margin-bottom: 8px;
    margin-top: 6px;
    overflow: hidden
}

.input-amount__prefix {
    font-size: 24px;
    margin-right: 8px
}

.input-amount__input {
    -webkit-appearance: none;
    border: none;
    -webkit-box-shadow: 0 0 0 2px transparent,0 0 0 3px transparent,0 0 0 5px transparent!important;
    box-shadow: 0 0 0 2px transparent,0 0 0 3px transparent,0 0 0 5px transparent!important;
    color: #000;
    font-family: Proxima Nova,-apple-system,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
    font-size: 60px;
    font-weight: 300;
    outline: none;
    padding: 0;
    -webkit-transition: width .1s ease;
    transition: width .1s ease;
    width: 100%
}

.input-amount__input.hiddenbox {
    -webkit-appearance: none;
    height: 0;
    min-width: 1ch;
    padding-left: 2px;
    visibility: hidden;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.input-amount__message {
    font-size: 14px;
    text-align: center
}

.input-amount__message--error {
    color: #f23d4f
}

.input-amount__message--default {
    color: rgba(0,0,0,.45)
}

.crono-amount-field--placeholder {
    color: rgba(0,0,0,.55)
}

.crono-amount-field__input-container {
    display: flex;
    font-size: 28px;
    margin-right: 8px
}

.crono-amount-field__input {
    -webkit-align-items: center;
    align-items: center;
    color: rgba(0,0,0,.9);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    font-weight: 400
}

.crono-amount-field--size-l .crono-amount-field__prefix {
    font-size: 28px;
    margin-right: 8px
}

.crono-amount-field__input {
    background-color: transparent;
    border: 0;
    bottom: 0;
    color: inherit;
    font: inherit;
    left: 0;
    line-height: normal;
    margin: 0;
    padding: 0;
    
    right: 0;
    top: 0;
    width: 100%
}

.amount-page {
    margin: 0 auto;
    max-width: 646px
}

.payTitulo{
    background: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
}
.payNumero{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:10px
}
