.app-user-head {
    background: #0d6efd;
    width: 100%;
    height: 112px;
    border-radius: 30%;
}
.aling{
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
}